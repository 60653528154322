import React from 'react'

import { AuthConsumer } from '../providers/AuthProvider'

import JobScreen from '../components/JobScreen'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const JobsPage = () => (
  <Layout menuSelectedKeys={['jobs']}>
    <SEO title="Jobs" />

    <AuthConsumer>
      {({ isLoading, data }) => {
        if (isLoading) {
          return <div>Loading...</div>
        }

        if (!data) {
          return <div>Not logged in</div>
        }

        return <JobScreen authData={data} />
      }}
    </AuthConsumer>
  </Layout>
)

export default JobsPage
